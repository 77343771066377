import React from "react";
import "../../styles/animate.css";
import "../../styles/screenie.scss";

import screenie_icon from "../../images/screenie_icon.png";
import {
  Button,
  Jumbotron,
} from "react-bootstrap";

import favicon from "../../images/screenieicon_16x16@2x.png";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function ScreeniePage() {

  return (
    <Layout pageInfo={{ pageName: "screenie" }}>
      <SEO
        title="Screenie - Search text in your images"
        favicon32={favicon}
        keywords={[
          `image manager`,
          `screenshot manager`,
          `search text in screenshots`
        ]}
      />
      <Jumbotron className="panel1">
        <div className="screeniePanelWrapper">
          <div className="screenieDownloadWrapper">
            <div className="screenieTextRightWrapper">
              <div className="screenieTextLogoCombo">
                <div className="screenieIconWrapper">
                  <img src={screenie_icon} height="120" className="" alt="" />
                </div>
                <div className="screenieTextRight">
                  <div className="screenie2"> Screenie 2 </div>
                  <div className="screenieTextRightTagline">
                    Your favorite screenshot manager reimagined
                  </div>
                </div>
              </div>

              <div className="pressKitBody">
                <span class="hiThere">Hi there! 👋</span> <br /> Thanks for
                checking out Screenie, we really appreciate your time. The Press
                Kit below will provide you with all the necessary high quality
                assets, descriptions, and marketing material you might need to
                help you write about Screenie. Whether you're interested in
                writing a review, have any questions, or just want to chat, feel
                free to contact us and we'll be sure to get back to you ASAP.
              </div>

              <div className="pressKitButtons">
                <a href="https://thnkdev.com/Screenie/Screenie Press Kit.zip">
                <Button
                  variant="primary"
                  size="lg"
                  className="downloadPressKitButton"
                >
                  Download Press Kit
                </Button>
                </a>
                <a href="mailto:thnkdev@gmail.com">
                <Button variant="info" size="lg" className="contactUsButton">
                  Contact us!
                </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Jumbotron>
    </Layout>
  );
}

export default ScreeniePage;
